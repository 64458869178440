define("discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem
    @path="/admin/plugins/{{this.adminPluginNavManager.currentPlugin.name}}/leaderboards"
    @label={{i18n "gamification.leaderboard.title"}}
  />
  <AdminEditLeaderboard @leaderboard={{@model}} />
  */
  {
    "id": "IClT4+si",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[[29,[\"/admin/plugins/\",[30,0,[\"adminPluginNavManager\",\"currentPlugin\",\"name\"]],\"/leaderboards\"]],[28,[37,1],[\"gamification.leaderboard.title\"],null]]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@leaderboard\"],[[30,1]]],null]],[\"@model\"],false,[\"d-breadcrumbs-item\",\"i18n\",\"admin-edit-leaderboard\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/admin-plugins/show/discourse-gamification-leaderboards/show.hbs",
    "isStrictMode": false
  });
});